import React from "react";
import Slider from "react-slick";
import loadable from "@loadable/component";
import { useSelector } from "react-redux";

import mainHero from "assets/images/mila-valentine.png";
import mainHeroWebp from "assets/images/mila-valentine.png?as=webp";
import mainHeroBlackWhite from "assets/images/mila-valentine-black-white.png";
import mainHeroBlackWhiteWebp from "assets/images/mila-valentine-black-white.png?as=webp";
import mainHeroMobileBlackWhite from "assets/images/mila-valentine-mobile-b-w.png";
import mainHeroMobileBlackWhiteWebp from "assets/images/mila-valentine-mobile-b-w.png?as=webp";
import mainHeroMobile from "assets/images/mila-valentine-mobile.png";
import mainHeroMobileWebp from "assets/images/mila-valentine-mobile.png?as=webp";

import { selectLocalizationData } from "../features/localization/localizationSlice";
import { SUPPORTED_REGIONS } from "../../shared/enums";

const ImageLoader = loadable(() => import("./common/ImageLoader"));

/**
 * @returns {JSX.Element}
 * @constructor
 */

const HeroValentine = () => {
  const localization = useSelector(selectLocalizationData);
  const isRegionMatch =
    localization.region === SUPPORTED_REGIONS.US ||
    localization.region === SUPPORTED_REGIONS.CA;

  const desktopImg = isRegionMatch ? mainHeroBlackWhite : mainHero;
  const desktopImgWebp = isRegionMatch ? mainHeroBlackWhiteWebp : mainHeroWebp;
  const mobileImg = isRegionMatch ? mainHeroMobileBlackWhite : mainHeroMobile;
  const mobileWebp = isRegionMatch
    ? mainHeroMobileBlackWhiteWebp
    : mainHeroMobileWebp;

  const settings = {
    autoplay: true,
    speed: 600,
    cssEase: "cubic-bezier(0.65,0.05,0.36,1)",
    slidesToShow: 4,
    autoplaySpeed: 2000,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: false,
    responsive: [
      {
        mobileFirst: true,
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          mobileFirst: true,
        },
      },
    ],
  };

  return (
    <>
      <section className="heroFlex heroFlex--valentine">
        <div className="awards awards--four">
          <div className="container">
            <Slider {...settings} className="awards__slider">
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘Best
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Popular Science</cite>
                </div>
              </blockquote>
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘A Truly Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>NYTimes/Wirecutter</cite>
                </div>
              </blockquote>
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘Best Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Homes & Gardens</cite>
                </div>
              </blockquote>
              <blockquote className="awards__item">
                <div className="awards__itemInner">
                  <h2>
                    ‘Best Smart
                    <br />
                    Air Purifier’
                  </h2>
                  <cite>Gear Patrol</cite>
                </div>
              </blockquote>
            </Slider>
          </div>
        </div>

        <div className="hero hero--slider hero--home-center hero--small">
          <h1 className="hero__title hero__title--small">
            Say hello <span>❤️</span> to
            <strong>Mila</strong>
          </h1>
          <ImageLoader
            className="hero__image hero__image--holiday img-relative"
            placeholderAspectRatio={1338 / 820}
            image={desktopImg}
            webp={desktopImgWebp}
            smallImage={mobileImg}
            smallWebp={mobileWebp}
            alt="Mila Filters boxes"
          />
          <div className="container">
            <div className="hero__content hero__content--desktop ">
              <h2 className="hero__subtitle hero__subtitle--alt">
                Put some love in the air.
              </h2>
              <div className="hero__description hero__description--small">
                <p>
                  Smart, thoughtful and cute. <br /> Meet the air purifier of
                  your dreams 💗
                </p>
              </div>
            </div>
            <div className="hero__content hero__content--phone hero__inner--red">
              <h2 className="hero__subtitle hero__subtitle--alt">
                Put some love in the air.
              </h2>
              <div className="hero__description hero__description--small">
                <p>
                  Smart, thoughtful and cute. <br /> Meet the air purifier of{" "}
                  <br /> your dreams 💗
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="price-table">
        <div className="container">
          <div className="price-table__footer">
            <h3 className="price-table__title">The Mila Promise</h3>
            <ul className="price-table__list">
              <li>
                <svg className="icon icon-money-back-guarantee">
                  <use xlinkHref="#icon-money-back-guarantee" />
                </svg>
                30 day Mila-backed guarantee
              </li>
              <li>
                <svg className="icon icon-shipping">
                  <use xlinkHref="#icon-shipping" />
                </svg>
                Fast free shipping with same/next business day shipping
              </li>
              <li>
                <svg className="icon icon-warranty">
                  <use xlinkHref="#icon-warranty" />
                </svg>
                Two year warranty
              </li>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeroValentine;
